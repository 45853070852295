import React from 'react';

import classNames from 'classnames';

import { getClickType } from '../../../sharedModules/getClickType';
import { SiteId } from '../../modules/features/BaseFeatures/BaseFeatures';
import type { Deal } from '../../types/Deal';
import getPriceType from '../../utils/getPriceType';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { MerchantlinkStyle } from '../GenericComponents/HawkWidget/model';

import getMerchantLinkCTAtext from './getMerchantLinkCTAtext';
import styles from './styles/merchant-link-widget-item.css';

interface MerchantLinkWidgetItemProps {
  deal: Deal;
  isCentered?: boolean;
}

const MerchantLinkWidgetItem: React.FC<MerchantLinkWidgetItemProps> = ({ deal, isCentered }) => {
  const {
    articleUrl,
    translate,
    buttonType,
    site,
    showPrice,
    showPrimaryLabel,
    genericSharedComponents: { AffiliateLink, DisplayPrice },
    merchantlinkTextType,
    merchantlinkStyle,
  } = useHawkWidgetContext();
  if (!deal) {
    return null;
  }

  const getContainerStyle = (): string => {
    if (merchantlinkStyle === MerchantlinkStyle.BOX) {
      return 'box';
    }

    if (buttonType === 'textbased') {
      return 'transparent';
    }

    if (
      site &&
      (site.id === SiteId.MARIE_CLAIREUS ||
        site.id === SiteId.MARIE_CLAIREUK ||
        site.id === SiteId.WH ||
        site.id === SiteId.WINDOWS_CENTRAL)
    ) {
      return 'mc-container';
    }
    return 'container';
  };

  return (
    <div className={classNames(styles['wrapper'], isCentered ? styles['centered'] : {})}>
      <div className={styles[getContainerStyle()]} data-product-type={deal?.product_type}>
        <AffiliateLink
          deal={deal}
          className={buttonType ? 'merchantlink-transparent-label' : 'merchantlink-label'}
          clickType={getClickType({
            link: deal.offer.link,
            articleUrl,
            productType: deal.product_type,
          })}
        >
          {deal?.offer?.price && showPrice === 'showPrice' && (
            <>
              <DisplayPrice
                deal={deal}
                className={buttonType ? 'merchantlink-transparent-price' : 'merchantlink-price'}
                type={getPriceType(deal)}
                wrapInLink={false}
              />{' '}
            </>
          )}
          {getMerchantLinkCTAtext(deal, translate, merchantlinkTextType)}
          {deal?.offer?.price && Number(deal.offer.price) > 0 && showPrice === 'stream' && (
            <>
              {' '}
              <DisplayPrice
                deal={deal}
                className="merchantlink-price"
                type={getPriceType(deal)}
                wrapInLink={false}
              />
            </>
          )}
        </AffiliateLink>
      </div>
      {showPrimaryLabel && deal?.offer?.display_primary_label && (
        <div className={styles['primary-label']}>{deal.offer.display_primary_label}</div>
      )}
    </div>
  );
};

export default MerchantLinkWidgetItem;
