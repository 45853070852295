import React, { useState } from 'react';

import classNames from 'classnames';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import { getSeasonalMerchantsWithCustomTrackingId } from '../../../sharedModules/getSeasonalMerchantsWithCustomTrackingId';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { ArticleType } from '../../modules/features/BaseFeatures/BaseFeatures';
import { BlockAlignmentClass, getBlockAlignmentClass } from '../../modules/getBlockAlignmentClass';
import { getInitialiser } from '../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import { MerchantLinkData } from '../../types/MerchantLinkData';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import type { WidgetProps } from '../../types/WidgetProps';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { MerchantlinkStyle } from '../GenericComponents/HawkWidget/model';
import { SeasonalEventCountdown } from '../GenericComponents/Seasonal/SeasonalEventCountdown';
import { SeasonalEventMerchants } from '../GenericComponents/Seasonal/SeasonalEventMerchants';

import { AvailableFrom } from './AvailableFrom';
import MerchantLinkWidgetItem from './MerchantLinkWidgetItem';
import MerchantLinkWidgetItemModern from './MerchantLinkWidgetItemModern';
import styles from './styles/merchant-link-widget.css';

loadCSS('merchantlink');

const MerchantLinkWidget: React.FC<WidgetProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetStandard, ModelCodeReviews, Title },
    name,
    blockLayout,
    blockType,
    platform,
  } = props;

  const [activeButton, setActiveButton] = useState(0);

  const handleMouseLeave = (): void => {
    setActiveButton(0);
  };

  return (
    <HawkWidgetStandard {...props}>
      <Consumer>
        {({
          dealData,
          widgetTitle,
          seasonal,
          params,
          defaultTab,
          availableFrom,
          merchantlinkStyle,
        }): JSX.Element => (
          <div className={styles['widget-container']} onMouseLeave={(): void => handleMouseLeave()}>
            <>
              {availableFrom &&
                name !== 'Product Block merchantlink widget' &&
                dealData?.deals?.length > 0 &&
                dealData?.dealType === 'deals' && <AvailableFrom deal={dealData.deals[0]} />}
              <div className={styles['main-container']}>
                <Title title={widgetTitle} className="merchantlink-title-buying-guide" />
                <div
                  className={
                    merchantlinkStyle === MerchantlinkStyle.MODERN_BUTTON
                      ? classNames(
                          styles['wrapper'],
                          styles[getBlockAlignmentClass(platform, blockLayout, blockType)],
                          styles['modern-wrapper'],
                        )
                      : classNames(
                          styles['wrapper'],
                          styles[getBlockAlignmentClass(platform, blockLayout, blockType)],
                        )
                  }
                >
                  {seasonal === 'event' &&
                    defaultTab &&
                    params[defaultTab.value].article_type === ArticleType.REVIEW && (
                      <SeasonalEventCountdown className="merchantlink" />
                    )}
                  {(dealData.deals || []).map((deal, index) => {
                    return merchantlinkStyle === MerchantlinkStyle.MODERN_BUTTON ? (
                      <MerchantLinkWidgetItemModern
                        deal={deal}
                        key={deal?.id}
                        activeButton={activeButton}
                        setActiveButton={setActiveButton}
                        index={index}
                      />
                    ) : (
                      <MerchantLinkWidgetItem
                        deal={deal}
                        key={deal?.id}
                        isCentered={
                          getBlockAlignmentClass(platform, blockLayout, blockType) ===
                          BlockAlignmentClass.CENTERED
                        }
                      />
                    );
                  })}
                  {seasonal === 'event' &&
                    defaultTab &&
                    params[defaultTab.value].article_type === ArticleType.REVIEW && (
                      <SeasonalEventMerchants className="merchantlink" />
                    )}
                  <ModelCodeReviews />
                </div>
              </div>
            </>
          </div>
        )}
      </Consumer>
    </HawkWidgetStandard>
  );
};

MerchantLinkWidget.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  const { data, seasonal, defaultTab, params, area } = props;

  if (
    data &&
    seasonal === 'event' &&
    defaultTab &&
    params[defaultTab.value].article_type === 'review'
  ) {
    const dataEvent = data[defaultTab.value].event;

    if (dataEvent) {
      const offerType = data && data[defaultTab?.value || '']['offer_type'];
      const battle = params && params[offerType || ''] && params[offerType || ''].battle;

      dataEvent.merchants = await getSeasonalMerchantsWithCustomTrackingId(
        dataEvent,
        battle,
        articleUrl,
        site,
        area,
      );
    }
  }

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default MerchantLinkWidget;
