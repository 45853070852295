import getButtonText from '../../modules/getButtonText';
import type { Deal } from '../../types/Deal';
import type { Translate } from '../../types/Localiser';

export default (deal: Deal, translate: Translate, merchantlinkTextType: string): string => {
  if (merchantlinkTextType) {
    const merchantName = deal?.merchant?.name;

    return translate(merchantlinkTextType, [merchantName || '']);
  }
  return getButtonText(deal, true, translate);
};
